import React, { useMemo } from 'react';
import { ArrowLeft, ArrowRight } from '../app/helpers/icons';
import Button from './global/Button';
import Icon from './global/Icon';
import StyledPagination from './Pagination.style';

const getShownPages = ({ items, pages, current }) => {
  const show = [];

  if (items >= pages) {
    // if we can show more items than there are pages, show all pages
    for (let p = 1; p <= pages; p++) show.push(p);
  } else {
    let itemsBefore = 0;
    let dotsBefore = false;

    // determine the number of items left from the current page
    if (current == 2) {
      itemsBefore = 1;
    } else if (current > 2) {
      if (items <= 2) {
        itemsBefore = 0;
        dotsBefore = false;
      } else if (items <= 4) {
        itemsBefore = 1;
        dotsBefore = false;
      } else {
        itemsBefore = Math.floor((items - 1) / 2);
        dotsBefore = true;
        if (itemsBefore >= current) {
          itemsBefore = current - 1;
          dotsBefore = false;
        }
      }
    }

    // calculate the number of items right from the current page
    let itemsAfter = items - 1 - itemsBefore;

    // if we have too many pages to the right, rebalance it
    while (itemsAfter + current > pages) {
      itemsAfter--;
      itemsBefore++;
    }

    // redetermine if we want to show a ... item to the left
    dotsBefore = dotsBefore && itemsBefore < current - 1;

    // determine if we want to show a ... item to the right
    let dotsAfter = items > 3 && itemsAfter > 1 && pages - current > itemsAfter;

    // show the first page
    if (itemsBefore >= 1) {
      show.push(1);
      itemsBefore--;
    }

    // show the ... item to the left
    if (dotsBefore) {
      show.push('dots-before');
      itemsBefore--;
    }

    // if we have too few items to the left, rebalance it
    while (itemsBefore < 0) {
      itemsAfter--;
      itemsBefore++;
    }

    // determine the range of sequential pages in the middle
    let firstInCenter = current - itemsBefore;
    let lastInCenter = current + itemsAfter;
    if (itemsAfter >= 1) lastInCenter -= 1;
    if (dotsAfter) lastInCenter -= 1;

    // show the middle range of pages
    for (let p = firstInCenter; p <= lastInCenter; p++) {
      show.push(p);
    }
    // show the ... item to the right
    if (dotsAfter) {
      show.push('dots-after');
    }
    // show the last page
    if (itemsAfter >= 1) {
      show.push(pages);
    }
  }
  return show;
};

const Pagination = ({
  current,
  pages,
  showPages,
  onClick,
  buttonProps,
  ...p
}) => {
  const items = showPages;

  const showItems = useMemo(
    () => getShownPages({ current, pages, items }),
    [current, pages, items]
  );
  if (!buttonProps) {
    buttonProps = (page) => ({
      onClick: onClick ? () => onClick?.(page) : onClick,
    });
  }
  return (
    <StyledPagination {...p}>
      <Button
        disabled={current <= 1}
        as={current <= 1 ? 'div' : undefined}
        {...buttonProps(current - 1)}
        large
        variant="ghost"
        rel={current <= 1 ? undefined : 'prev'}
      >
        <Icon {...ArrowLeft} title="Previous" />
      </Button>
      {showItems.map((p) => {
        const dots = p === 'dots-before' || p === 'dots-after';
        return (
          <Button
            disabled={dots || p === current}
            {...(dots ? { as: 'div' } : buttonProps(p))}
            className={p === current ? 'active' : undefined}
            large
            variant="ghost"
            key={p}
          >
            {dots ? '...' : p}
          </Button>
        );
      })}
      <Button
        disabled={current >= pages}
        as={current >= pages ? 'div' : undefined}
        {...buttonProps(current + 1)}
        large
        variant="ghost"
        rel={current >= pages ? undefined : 'next'}
      >
        <Icon {...ArrowRight} title="Next" />
      </Button>
    </StyledPagination>
  );
};
export default Pagination;
