import styled from '@emotion/styled';
import Container from '../../components/global/Container';

export default styled(Container)`
  --cols: 1;
  gap: 15px;
  display: grid;

  ${({ theme }) => theme.media.laptop} {
    --cols: 2;
  }

  ${({ theme }) => theme.media.desktopSmall} {
    --cols: 3;
    gap: 30px;
  }

  grid-template-columns: repeat(var(--cols), 1fr);

  .pagination {
    grid-column-start: 1;
    grid-column-end: calc(var(--cols) + 1);
    ${({ theme }) => theme.media.laptop} {
      justify-self: center;
    }
  }
`;
