import styled from '@emotion/styled';
import ButtonStyle from './global/Button.style';

export default styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
  ${ButtonStyle} {
    padding: 10px;
    min-width: 48px;
  }
  ${({ theme }) => theme.media.tablet} {
    align-self: center;
  }
`;
