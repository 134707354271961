import React from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import StyledModule from './module.style';
import BlogPostCard from '../../components/BlogPostCard';
import Pagination from '../../components/Pagination';
import { useBreakpoint } from '../../app/providers/breakpointProvider';

const BlogListingModule = ({ blogDetails, blogPosts, pagination, ...p }) => {
  const maxPages = Number(pagination?.show);
  const showPages = useBreakpoint().select({
    mobile: Math.min(4, maxPages),
    tablet: Math.min(7, maxPages),
  });
  return (
    <StyledModule xPaddingAsMargin flex col gap="15px" {...p}>
      {blogPosts?.map((blog, ix) => (
        <BlogPostCard key={ix} {...blog} linkText={blogDetails.linkText} />
      ))}
      {pagination && (
        <Pagination
          className="pagination"
          current={Number(pagination.current)}
          pages={Number(pagination.total)}
          showPages={showPages}
          buttonProps={(page) =>
            pagination.pages.find((p) => Number(p.page) === page) || {
              disabled: true,
            }
          }
        />
      )}
    </StyledModule>
  );
};

export default BlogListingModule;

registerModule({ BlogListing: Self });
