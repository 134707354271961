import styled from '@emotion/styled';

export default styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    img {
        max-width: 100%;
        height: 230px;
        object-fit: cover;
        border-radius: 4px 4px 0 0;
    }

    .blog-content {
        padding: 30px;
        border: solid 1px ${({ theme }) => theme.colors.quartz};
        border-radius: 0 0 4px 4px;
        display: flex;
        flex-direction: column;
        height: 100%;

        .title {
            margin-bottom: 5px;
        }

        .date, .description {
            margin-bottom: 15px;
        }

        .read-more {
            display: flex;
            align-items: center;
            margin-top: auto;

            ${({ theme }) => theme.media.hover} {
                &:hover svg {
                    fill: ${({ theme }) => theme.colors.bahamaBlue};
                }
            }

            svg {
                margin-left: 12px;
            }
        }
    }
`;
