import React from 'react';
import StyledBlogPostCard from './BlogPostCard.style';
import Typography from '../components/global/Typography';
import Link from '../components/global/Link';
import Icon from '../components/global/Icon';
import { ArrowRight } from '../app/helpers/icons';

const BlogPostCard = ({
    title,
    imgSrc,
    postDate,
    description,
    linkText,
    url,
    ...p
}) => {
    return (
        <StyledBlogPostCard {...p}>
            <img src={imgSrc} alt={`${title} image`} loading="lazy" />
            <div className='blog-content'>
                <Typography
                    variant="heading2"
                    color="bahamaBlue"
                    className="title"
                >
                    {title}
                </Typography>
                <Typography
                    variant="controlStrong"
                    color="bahamaBlue"
                    className="date"
                >
                    {postDate}
                </Typography>
                <Typography
                    color="blueCharcoal"
                    className="description"
                >
                    {description}
                </Typography>
                <Link
                    href={url}
                    className="read-more"
                >
                    {linkText}
                    <Icon {...ArrowRight} fill="pacificBlue" />
                </Link>
            </div>
        </StyledBlogPostCard>
    );
};
export default BlogPostCard;
